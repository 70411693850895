<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
              ref="search"
              :inline="true"
              size="small"
          >
            <el-form-item label="" class="searchItem">
              <el-input v-model="pageParams.keys" size="mini" placeholder="请输入分组名称/组内成员" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="search" size="mini" >查询</el-button>
              <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="addMore" size="mini" v-if="$anthButtons.getButtonAuth('yafztj')">添加</el-button>
          <el-table
              ref="tableData"
              :data="tableData"
              style="margin-top: 10px"
              border
              :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
              height="calc(100vh - 330px)"
              :stripe="true"
          >
            <el-table-column
                type="index"
                label="序号"
                width="50"
            >
            </el-table-column>
            <el-table-column
                prop="groupName"
                label="分组名称"
            >
            </el-table-column>
            <el-table-column
                prop="groupUsersName"
                label="组内成员"
                width="700"
            >
            </el-table-column>
            <el-table-column
                prop="editName"
                label="编辑人"
            >
            </el-table-column>
            <el-table-column
                prop="editTime"
                label="编辑时间"
            >
            </el-table-column>
            <el-table-column
                prop="name"
                label="操作"
                width="200"
            >
              <template slot-scope="{row}">
                <el-button type="primary" size="mini" style="margin-right: 10px" @click="modify(row)" v-if="$anthButtons.getButtonAuth('yafzbj')">编辑</el-button>
                <el-button type="danger" size="mini" style="margin-right: 10px" @click="handle(row)" v-if="$anthButtons.getButtonAuth('yafzsc')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="total">
            <el-pagination
                background
                layout="total, prev, pager, next,sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageParams.current"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="uodataStatus?'新增预警小组':'编辑预警小组'"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        @close="closeDialog('pgform')"
    >
      <el-form ref="pgform" :model="pgform" label-width="80px" :rules="rules">
        <el-form-item label="分组名称" prop="groupName">
          <el-input type="text" placeholder="请输入" v-model="pgform.groupName"></el-input>
        </el-form-item>
        <el-form-item label="组内成员" prop="groupUsersId">
          <el-select v-model="pgform.groupUsersId" clearable placeholder="请选择" style="width: 100%" filterable multiple collapse-tags>
            <el-option
                v-for="item in DataList"
                :key="item.id"
                :label="item.realname"
                :value="item.id+''"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="uodataStatus" type="primary" @click="submit('pgform')">确 定</el-button>
        <el-button v-else type="primary" @click="modifysubmit('pgform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  userList,
  deleteusergroup, GroupqueryByPage, addGroup, updateGroup
} from "../../RequestPort/kfOrder";

export default {
  name: "statistics",
  data(){
    return{
      uodataStatus:true,
      dialogVisible:false,
      pageParams:{
        keys:'',
        size: 100,
        current:1
      },
      total:null,
      time:[],
      tableData:[],
      pgform:{
        groupName:'',
        groupUsersId:[],
      },
      DataList:[],
      rules:{
        groupName:[{ required: true, message: "请输入", trigger: "change" }],
        groupUsersId:[{ required: true, message: "请选择", trigger: "change" }],
      }
    }
  },
  mounted() {
    userList().then(res=>{
      this.DataList = res.data
    })
    this.loadList(this.pageParams);
  },
  methods:{
    closeDialog(formName){
      this.pgform = {
        groupName:'',
        groupUsersId:[]
      }
          this.$refs[formName].resetFields(); // 重置表单移除校验
    },
    handle(row){
      this.$confirm('是否删除该小组？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        deleteusergroup({id:row.id}).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.loadList(this.pageParams);
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submit(pgform){
      this.$refs[pgform].validate((valid) => {
        if(!valid) return
        let obj = {
          groupName:this.pgform.groupName,
          groupUsersId:this.pgform.groupUsersId.toString()
        }
        addGroup(obj).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogVisible = false
            this.loadList(this.pageParams);
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    modifysubmit(pgform){
      this.$refs[pgform].validate((valid) => {
        if(!valid) return
        let obj = {
          id:this.pgform.id,
          groupName:this.pgform.groupName,
          groupUsersId:this.pgform.groupUsersId.toString()
        }
        updateGroup(obj).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogVisible = false
            this.loadList(this.pageParams);
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    modify(row){
      this.uodataStatus = false
      let obj = JSON.parse(JSON.stringify(row))
      this.pgform = obj
      this.pgform.groupUsersId = obj.groupUsersId.split(',')
      this.dialogVisible = true
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        keys:'',
        current: 1,
        size: 100
      };
      this.loadList(this.pageParams);
    },
    addMore(){
      this.uodataStatus = true
      this.dialogVisible = true
    },
    loadList(obj){
      GroupqueryByPage(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
  }
}
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{
  height: 25px;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 10px;
  padding-left: 20px;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 190px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>